.responsive-embed {
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  display: block;
}
.responsive-embed iframe,
.responsive-embed object,
.responsive-embed embed {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.responsive-embed-16-9 {
  padding-bottom: 56.25%;
}

.responsive-embed-4-3 {
  padding-bottom: 75%;
}