.navbar-header {
  .menu-item {
    a {
      &:after {
        @apply absolute left-0 block w-full h-px bg-black transform scale-x-0 origin-right transition-transform duration-500;
        bottom: -6px;
        content: "";
      }

      &:hover {
        &:after {
          @apply scale-x-100 origin-left;
        }
      }
    }
  }

  .navbar-header__logo {

    svg {
      width: 79px;
      height: 19px;

      @screen lg {
        width: 120px;
        height: 30px;
      }
    }
  }

  &__main {
    margin-bottom: 5px;
  }

  .current-menu-item {
    border: none;
    text-decoration: none !important;
    text-underline-offset: 0px !important;

    a:after {
      @apply scale-x-100 origin-left;
    }
  }
}

body.--menu-screen-modal-opened {
  .navbar-header {
    background-color: transparent !important;

    path {
      &:not(.country) {
        @apply text-white;
      }

      &.country {
        @apply text-primary;
      }
    }

    &__links li {
      @apply opacity-0 pointer-events-none;
    }
  }
}

.header--dark {
  .navbar-header {
    filter: invert(1);
  }
}
