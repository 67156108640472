body.journal-theme {
  position: relative;
  background: radial-gradient(
                  circle,
                  rgba(219, 139, 117, 0.8) 0%,
                  rgba(255, 255, 255, 1) 100%
  ) fixed;
}

body.experience-theme {
  position: relative;
  background: radial-gradient(
                  circle,
                  rgba(102, 145, 156, 1) 0%,
                  rgba(255, 255, 255, 1) 100%
  ) fixed;
}

body.journal-theme:after,
body.experience-theme:after {
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: linear-gradient(transparent, #ffffff 200vh);
}

.site {
  @apply max-w-7xl mx-auto;
}

.site-grid {
  @apply grid grid-cols-12;
}

.site-gap {
  @apply gap-site-mobile lg:gap-site-desktop;
}

.site-padding {
  @apply px-site-mobile lg:px-site-desktop;
}

.container {
  @apply px-site-mobile lg:px-site-desktop;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 500;
}

.wysiwyg {
  @apply tracking-[.5px];

  p, li { 
    @apply text-paragraph-l;
  }
  p {
    @apply mb-[1rem];
  }
  ul {
    @apply list-disc pl-[1.625em] mb-[1rem];
  }
  ol {
    @apply list-decimal mb-[1rem];
  }
}

h1, .title-h1 {
  @apply text-h1-mobile lg:text-h1-desktop break-words;
}

h2, .title-h2 {
  @apply text-h2-mobile lg:text-h2-desktop break-words;
}

h3, .title-h3 {
  @apply text-h3-mobile lg:text-h3-desktop break-words;
}

h4, .title-h4 {
  @apply text-h4-mobile lg:text-h4-desktop break-words;
}

h5, .title-h5 {
  @apply text-h5-mobile lg:text-h5-desktop break-words;
}

h6, .title-h6 {
  @apply text-h6-mobile lg:text-h6-desktop break-words;
}

.paragraph-bold {
  @apply font-bold;
}

.paragraph-italic {
  @apply italic;
}

.paragraph-bordered {
  @apply border-black border-y py-10 mb-10 lg:border-y-0 lg:border-l lg:py-0 lg:pl-5 lg:w-8/12;

  &>*:last-child {
    @apply mb-0;
  }
}

* + .paragraph-bordered {
  @apply mt-10;
}

.paragraph-align-center {
  @apply text-center;
}

.paragraph-align-right {
  @apply text-right;
}

.circular-arrow svg {
  width: 50px;
}