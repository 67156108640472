.cta {
  &:not(.cta--underline) {
    @apply hover:bg-secondary hover:border-secondary hover:text-white;
    padding: 10px 30px;
  }

  &--underline {
    @apply relative inline-block text-paragraph-m;

    &:after {
      @apply absolute left-0 w-full h-px bg-black;
      bottom: -2px;
      content: "";
    }
  }
}

.experience-theme {
  .cta {
    @apply hover:bg-secondary hover:border-secondary hover:text-white;
  }
}

[class*="--dark"] {
  .cta {
    @apply text-white border-white;
  }
}
