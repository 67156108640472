

.slider--post {
  --swiper-theme-color: rgb(13, 13, 13);
  --swiper-pagination-color: rgb(13, 13, 13);
  --swiper-pagination-bullet-opacity: 0.6;
  --swiper-pagination-bullet-inactive-color: rgb(13, 13, 13);
  --swiper-pagination-bullet-inactive-opacity: 0.3;
  --swiper-pagination-bullet-width: 7px;
  --swiper-pagination-bullet-height: 7px;

  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-pagination-bullet-active {
    height: 10px;
    width: 10px;
  }
}
