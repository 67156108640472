.single-journal {
    background-color: rgb(239, 232, 233);
    background-attachment: fixed;

    .tags {
        .tag {
            @apply border-primary text-primary
        }
    }

    .block-full {
        .wysiwyg-link {
            @apply ml-auto mr-auto lg:max-w-[920px]
        }
    }
}

.journal-theme {
    .cpt-modal__content {
        .block-full {
            .wysiwyg-link {
                @apply ml-auto mr-auto lg:max-w-[920px]
            }
        }
    }
}
