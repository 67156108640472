.language-header {

    ul {
        margin-bottom: 5px;
    }

    ul li {
        &:not(:first-child){
            &:before {
                @apply block rounded-full bg-black;
                content: '';
                width: 2px;
                height: 2px;
            }
        }
    }
}