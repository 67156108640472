.modal-container {
  pointer-events: none;
  background: rgba(0, 0, 0, .9);
	/*removes it from the screen*/
  max-height: 0px;
  opacity: 0%;
  transition: opacity .3s 0s, max-height 0s .3s;

	/* match its height to the normal header's */
  .header {
    height: 38px;
    @media screen and (max-width: 1280px) {
      height: 30px;
    }
  }
  svg {
    height: 30px;
    width: auto;
  }
}
body.with-modal-open {
  overflow: hidden;
}
.modal-container.modal-open {
  pointer-events: auto;
	/* can't animate `auto`, and max-height has a transition-duration of 0s anyways*/
  max-height: 99999px;
  opacity: 100%;
  transition: opacity .3s 0s, max-height 0s 0s;
}