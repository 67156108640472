/* Ingobo tutte le classi di swiper dentro .brands
 per non farlo andare in conflitto con gli altri
 slider della pagina */
.brands {
  .swiper {
    width: 100%;
    height: 100%;
  }
  .swiper-wrapper {
    transition-timing-function: linear;
  }
}
