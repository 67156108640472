  $max-width-elem: 90%;
  .crmform{
  /* impostazioni grafiche form */
  $border-bottom-color: #000;
  $submit-border-color: rgb(0 0 0);
  /* Inizio CSS per Form generati da servizio esterno */
    max-width: 90%;
    margin: 0 auto;
  
    .wrapperContainer:after {
      content: "";
      display: table;
      clear: both;
    }
  
    .wrapperContainer.leftcolumn,
    .wrapperContainer.rightcolumn{
      width: 45%;
      margin-bottom: 20px;
    }
    .wrapperContainer.leftcolumn{
      float:left;
    }
  
    .wrapperContainer.rightcolumn{
      float:right;
    }

@media only screen and (max-width: 400px) {
  .wrapperContainer.leftcolumn,
  .wrapperContainer.rightcolumn {
    width:100%;
    clear:both;
    display:block;
  }
}
  
    input,
    textarea,
    select {
      width:100%;
      background:transparent;
      border-bottom: 1px solid #000;
    }
  
    input:not([type="submit"]),
    textarea {
      border-radius: 0 !important;
    }
  
    input[type="submit"] {
      @apply text-primary;
    }
  
    input[type="submit"]:not([disabled]) {
      @apply cursor-pointer text-primary hover:bg-tertiary hover:border-tertiary;
    }
  
    textarea {
      resize: none;
    }
      input[type="checkbox"] { 
        float: left !important;
        width: 20px !important;
        margin: 6px;
        height: initial;
        border-width: 0px !important;
      }
      
    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus, 
    input:-webkit-autofill:active  {
      transition: background-color 10000s;
    }
    .lp-form-button{
    display: inline-block;
    white-space: nowrap;
    border-radius: 9999px;
    border-bottom-right-radius: 9999px;
    border-width: 1px;
    border-style: solid;
    --tw-border-opacity: 1;
    border-color: #000;
    font-size: 0.9375rem;
    line-height: 1.25rem;
    letter-spacing: 0.0313rem;
    -webkit-text-decoration-line: none;
    text-decoration-line: none;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
    padding: 10px 30px;
    margin-top:40px;
}
 }


 $border-bottom-color-wh: #fff;
 $submit-border-color-wh: rgb(255 255 255);
 $border-bottom-color: #000;
 $submit-border-color: rgb(0 0 0);
 
 .text-white{
   .crmform{
     max-width: 90%;
     margin: 0 auto;

 .wrapperContainer:after {
   content: "";
   display: table;
   clear: both;
 }

 .wrapperContainer.leftcolumn,
 .wrapperContainer.rightcolumn{
   width: 45%;
   margin-bottom: 20px;
 }
 .wrapperContainer.leftcolumn{
   float:left;
 }

 .wrapperContainer.rightcolumn{
   float:right;
 }

}

 input,
 textarea,
 select {
   width:100%;
   background:transparent;
   border-bottom: 1px solid #fff;
 }

 input:not([type="submit"]),
 textarea {
   border-radius: 0 !important;
 }

 input[type="submit"] {
   @apply text-primary;
 }

 input[type="submit"]:not([disabled]) {
   @apply cursor-pointer text-primary hover:bg-tertiary  hover:border-tertiary;
 }

 textarea {
   resize: none;
 }
   input[type="checkbox"] { 
     float: left !important;
     width: 20px !important;
     margin: 6px;
     height: initial;
     border-width: 0px !important;
   }
   
 input:-webkit-autofill,
 input:-webkit-autofill:hover, 
 input:-webkit-autofill:focus, 
 input:-webkit-autofill:active  {
   transition: background-color 10000s;
 }
 .lp-form-button{
 display: inline-block;
 white-space: nowrap;
 border-radius: 9999px;
 border-bottom-right-radius: 9999px;
 border-width: 1px;
 border-style: solid;
 --tw-border-opacity: 1;
 border-color: #fff;
 font-size: 0.9375rem;
 line-height: 1.25rem;
 letter-spacing: 0.0313rem;
 -webkit-text-decoration-line: none;
 text-decoration-line: none;
 transition-property: all;
 transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
 transition-duration: 150ms;
 padding: 10px 30px;
 margin-top:40px;
}
}
   /* Fine CSS per Form generati da servizio esterno */