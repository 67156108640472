section.component {
  /*padding*/
  &--pt-sm {
    @apply pt-6;
  }
  &--pt-m {
    @apply pt-s md:pt-10;
  }
  &--pt-lg {
    @apply pt-m md:pt-20;
  }
  &--pt-xl {
    @apply pt-l md:pt-40;
  }
  &--pt-2xl {
    @apply pt-xl md:pt-60;
  }

  &--pb-sm {
    @apply pb-6;
  }
  &--pb-m {
    @apply pb-s md:pb-10;
  }
  &--pb-lg {
    @apply pb-m md:pb-20;
  }
  &--pb-xl {
    @apply pb-l md:pb-40;
  }
  &--pb-2xl {
    @apply pb-xl md:pb-60;
  }

  /* Margin top */

  &--mt-sm {
    @apply mt-6;
  }
  &--mt-m {
    @apply mt-s md:mt-10;
  }
  &--mt-lg {
    @apply mt-m md:mt-20;
  }
  &--mt-xl {
    @apply mt-l md:mt-40;
  }
  &--mt-2xl {
    @apply mt-xl md:mt-60;
  }
  

  /* Theme */
  &--bg-white {
    @apply bg-white;
    /* fix "white pixel between section" issue */
    box-shadow: 0 0 0 2px theme("colors.white");
  }

  &--bg-primary {
    @apply bg-primary;
    /* fix "white pixel between section" issue */
    box-shadow: 0 0 0 2px theme("colors.primary");
  }

  &--bg-secondary {
    @apply bg-secondary;
    /* fix "white pixel between section" issue */
    box-shadow: 0 0 0 2px theme("colors.secondary");
  }

  &--bg-gray-1 {
    @apply bg-gray-1;
    /* fix "white pixel between section" issue */
    box-shadow: 0 0 0 2px theme("colors.gray-1");
  }

  &--bg-gray-2 {
    @apply bg-gray-2;
    /* fix "white pixel between section" issue */
    box-shadow: 0 0 0 2px theme("colors.gray-2");
  }

  &--bg-gray-3 {
    @apply bg-gray-3;
    /* fix "white pixel between section" issue */
    box-shadow: 0 0 0 2px theme("colors.gray-3");
  }

  &--bg-gray-4 {
    @apply bg-gray-4;
    /* fix "white pixel between section" issue */
    box-shadow: 0 0 0 2px theme("colors.gray-4");
  }

  /** Modifiers */

  &--9\/12-height {
    @apply min-h-[75vh] flex flex-col justify-center;
  }

  &--full-height {
    @apply min-h-[95vh] flex flex-col justify-center;
  }
}
