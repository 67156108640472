.cpt-modal {
  @apply transform translate-y-full opacity-0 transition-all duration-500;

  &__content {
    .container {
      @apply max-w-none lg:px-5;
    }
    section {
      &:first-child {
        @apply pt-5 lg:pt-20;
      }
    }
  }

  &--fetching {
    .cpt-modal {

      &__spinner {
        @apply opacity-100;
      }

      &__content, footer {
        @apply opacity-0;
      }
    }
  }
}

body.--cpt-modal-opened {
  overflow: hidden;

  .cpt-modal {
    @apply translate-y-0 opacity-100;

    &__underlay {
      @apply opacity-10;
    }
  }
}

body.--cpt-modal-opened {
  .cpt-modal:not(.cpt-modal--fetching) {
    .cpt-modal__content, footer {
      @apply opacity-100;
    }
  }
}

[data-post-type="journal"] {
  .cpt-modal {
    &__bg {
      @apply bg-gray-2;
    }

    &__cta {
      @apply hover:text-primary
    }
  }


  .tags {
    .tag {
      @apply border-primary text-primary
    }
  }
}

[data-post-type="experience"] {

  .cpt-modal {
    &__bg {
      @apply bg-gray-1;
    }

    &__cta {
      @apply hover:text-secondary
    }
  }

  .tags {
    .tag {
      @apply border-secondary text-secondary
    }
  }
}