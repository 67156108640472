.background-radial {
    &__circle {
        &:nth-child(1) {
            width: 150%;
            height: 150%;
            border-radius: 150%;
            background: -moz-radial-gradient(center, ellipse cover, rgba(102, 145, 156, 1) 0%, rgba(102, 145, 156, 0) 70%);
            background: -webkit-radial-gradient(center, ellipse cover, rgba(102, 145, 156, 1) 0%, rgba(102, 145, 156, 0) 70%);
            background: radial-gradient(ellipse at center, rgba(102, 145, 156, 1) 0%, rgba(102, 145, 156, 0) 70%);
            filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#66919c', endColorstr='#0066919c', GradientType=1);
            top: -50%;
            left: -60%;
            overflow: hidden;
            margin: auto;
            animation: circle 10s linear infinite;
        }
        
        &:nth-child(2) {
            width: 200%;
            height: 200%;
            background: -moz-radial-gradient(center, ellipse cover, rgba(219, 139, 117, 1) 0%, rgba(219, 139, 117, 0) 70%);
            background: -webkit-radial-gradient(center, ellipse cover, rgba(219, 139, 117, 1) 0%, rgba(219, 139, 117, 0) 70%);
            background: radial-gradient(ellipse at center, rgba(219, 139, 117, 1) 0%, rgba(219, 139, 117, 0) 70%);
            filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#db8b75', endColorstr='#00db8b75', GradientType=1);
            border-radius: 150%;
            bottom: -100%;
            right: -100%;
            overflow: hidden;
            margin: auto;
            animation: circle 8s linear reverse infinite;
        }
        
        &:nth-child(3) {
            width: 150%;
            height: 150%;
            border-radius: 150%;
            background: -moz-radial-gradient(center, ellipse cover, rgba(219, 139, 117, 1) 0%, rgba(231, 191, 193, 0) 70%);
            background: -webkit-radial-gradient(center, ellipse cover, rgba(219, 139, 117, 1) 0%, rgba(231, 191, 193, 0) 70%);
            background: radial-gradient(ellipse at center, rgba(219, 139, 117, 1) 0%, rgba(231, 191, 193, 0) 70%);
            filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#db8b75', endColorstr='#00e7bfc1', GradientType=1);
            bottom: -100%;
            left: -50%;
            overflow: hidden;
            margin: auto;
            animation: circle 8s linear reverse infinite;
        }
    }
}

.background-radial--paused {
    .background-radial__circle {
        animation-play-state: paused;
    }
}

@keyframes circle {
    0% {
        transform: rotate(0deg) translate(-4%) rotate(0deg);
    }
    100% {
        transform: rotate(360deg) translate(-4%) rotate(-360deg);
    }
}