.modal-form-contact {
  z-index: -10000;
  pointer-events: none;
  opacity: 0;
  transition: 300ms opacity;
  &__dialog {
    transform: translateY(10%);
    transition: 300ms transform !important;
  }
  &__failure {
    display: none;
  }
}
body.body--modal-form-contact-opened {
  .modal-form-contact {
    z-index: auto;
    pointer-events: auto;
    opacity: 100%;
    transition: 300ms opacity !important;
    &__dialog {
      transform: translateY(0);
      transition: 300ms transform !important;
    }
  }
}
body.body--modal-form-contact-failure {
  .modal-form-contact {
    &__failure {
      display: block;
    }
    &__success {
      display: none;
    }
  }
}