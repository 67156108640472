.wysiwyg {
  position: relative;

  a {
    @apply underline underline-offset-2;
  }

  &-text {
    &--xs {
      @apply text-paragraph-xs;
    }

    &--s {
      @apply text-paragraph-s;
    }

    &--m {
      @apply text-paragraph-m;
    }

    &--l {
      @apply text-paragraph-l;
    }
  }

  &-title {
    &--h1 {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        @apply text-h1-mobile lg:text-h1-desktop;
      }
    }

    &--h2 {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        @apply text-h2-mobile lg:text-h2-desktop;
      }
    }

    &--h3 {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        @apply text-h3-mobile lg:text-h3-desktop;
      }
    }

    &--h4 {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        @apply text-h4-mobile lg:text-h4-desktop;
      }
    }

    &--h5 {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        @apply text-h5-mobile lg:text-h5-desktop;
      }
    }

    &--h6 {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        @apply text-h6-mobile lg:text-h6-desktop;
      }
    }
  }

  &--bundle-editorial_border {
    @apply lg:border-l lg:border-secondary-300 lg:pl-2 lg:w-8/12;
  }
}
