.slider--draggable-content {
  .draggable-content {

    cursor: url('/app/themes/efesto-starter-theme/static/svg/cursor-drag-primary.svg') 42 42, grab;

    .swiper {
      width: 100%;
      height: 100%;
      z-index: 10;
      overflow: visible;
    }
    &:active {
      cursor: url('/app/themes/efesto-starter-theme/static/svg/cursor-drag-primary-active.svg') 42 42, grab;
    }
  }
}
