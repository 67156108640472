.hamburger {
  width: 20px;
  height: 20px;
  position: relative;
  cursor: pointer;
  span {
    transition: width .25s, height .25s ease-in-out;
  }
  &__lines {
    position: absolute;
    height: 100%;
    width: 100%;
    span {
      width: 100%;
      height: 1px;
      position: relative;
      top: 0;
      left: 0;
      margin: 6px 0;
      display: block;

      &:nth-child(1) {
        transition-delay: .5s;
      }
      &:nth-child(2) {
        transition-delay: .25s;
        margin-right: 40%;
      }
    }
  }
  &__cross {
    position: absolute;
    height: 100%;
    width: 100%;
    transform: rotate(45deg);
    span {
      &:nth-child(1) {
        height: 0;
        width: 1px;
        position: absolute;
        top: 2px;
        left: 10px;
        transition-delay: height 0s;
      }
      &:nth-child(2) {
        width: 0;
        height: 1px;
        position: absolute;
        left: 2px;
        top: 10px;
        transition-delay: width .25s;
      }
    }
  }
}
.--menu-screen-modal-opened .hamburger {
  &__lines {
    span {
      width: 0%;
      &:nth-child(1) {
        transition-delay: 0s;
      }
      &:nth-child(2) {
        transition-delay: .125s;
      }
    }
  }
  &__cross {
    span {
      &:nth-child(1) {
        height: 17px;
        transition-delay: .625s;
        top: 2px;
      }
    }
    span {
      &:nth-child(2) {
        width: 17px;
        transition-delay: .375s;
        left: 2px;
      }
    }
  }
}