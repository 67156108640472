.podcast {
  .sound {
    .play {
      display: none;
    }
  }

  .sound.playing {
    .play {
      display: block;
    }

    .pause {
      display: none;
    }
  }
}
