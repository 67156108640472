#content-before-consent {
    background-color: #EFEFEF;
    padding: 50px;
    text-align: center;
    border-radius: 30px;
    .content-consent {
        max-width: 500px;
        margin: 0 auto;
    }
    p {
        margin: 0;
    }
    button {
        margin-top: 30px;
        color: #000;
    }
}