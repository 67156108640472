.menu-screen {
  @apply transform translate-x-full;
}
body.--menu-screen-modal-opened {
  overflow: hidden;

  .menu-screen {
    @apply transform translate-x-0;
  }

}