.form {
  input:not([type="submit"]),
  textarea {
    border-radius: 0 !important;
  }

  input[type="submit"]:not([disabled]) {
    @apply cursor-pointer hover:text-white hover:border-tertiary;
  }

  textarea {
    resize: none;
  }

  select {
    padding-right: 1.5rem;
    background-image: url("/app/themes/efesto-starter-theme/static/svg/icon-plus.svg");
    background-repeat: no-repeat;
    background-position: right .5rem top 0.4rem;
  }

  input:focus, input:focus-visible,
  select:focus, select:focus-visible,
  textarea:focus, textarea:focus-visible {
    outline: none !important
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus, 
  input:-webkit-autofill:active  {
    transition: background-color 10000s;
  }

  .select-dropdown {
    @apply relative inline-block;
    > ul {
      @apply hidden absolute left-0 top-full w-full z-10 rounded-b overflow-hidden bg-white bg-opacity-95;
    }
    li {
      @apply px-2.5 py-2 cursor-pointer;
    }
  }

  &__acceptance {
    label {
      @apply text-md;
    }
    input[type="checkbox"] {
      @apply h-0 mr-2.5 !border-0;
      -webkit-appearance: none;
      appearance: none;
      &::before {
        content: "";
        white-space: pre;
        display: inline;
        @apply w-1 h-1 border rounded-sm block absolute left-0 text-center text-[19px] leading-[0.8];
      }
    }
    input[type="checkbox"]:checked {
      &::before {
        content: "\2713";
      }
    }
  }

  .wpcf7-form-control-wrap {
    display: block;
  }

  .wpcf7-not-valid-tip {
    @apply text-sm mt-1.5 text-danger;
  }

  .wpcf7-response-output {
    @apply !border-transparent underline text-lg !p-1 !px-3 !m-0 !my-2 text-black rounded-sm;
    background: rgba(255,255,255,0.7);
    border: 1px solid #000 !important;
  }
}

[class*="--dark"] {
  .form {
    @apply prose-p:text-white prose-headings:text-white text-white placeholder:text-white;

    input,
    textarea,
    select {
      @apply bg-[transparent] placeholder:text-white border-white;
    }

    input[type="submit"]:not([disabled]) {
      @apply hover:bg-white hover:text-tertiary;
    }

    select {
      background-image: url("/app/themes/efesto-starter-theme/static/svg/icon-plus-white.svg");
      background-repeat: no-repeat;
      background-position: center right;
    }

    option {
      @apply text-black;
    }

    li.selected {
      @apply text-white;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus, 
    input:-webkit-autofill:active  {
      -webkit-text-fill-color: #fff !important;
    }

    .select-dropdown {
      > ul {
        @apply bg-white;
        li {
          @apply text-black;
        }
      }
    }
  }
}

.form--contact .form__submit {
  grid-column: 1 !important;
}