.form {
  &--newsletter {
    input:not([type='submit']) {
      @apply border-b border-black pb-2 placeholder:text-black w-full text-paragraph-m;
    }

    .form {
      &__wrapper {
        @apply grid grid-cols-1 lg:grid-cols-2 gap-x-5 gap-y-5 text-paragraph-s;

        .wpcf7-form-control-wrap {
          @apply px-[1px] lg:col-span-2;
        }
      }

      &__email {
        @apply lg:col-span-2;
      }

      &__acceptance {
        @apply lg:col-span-2 text-paragraph-s;

        input[type="checkbox"] {
          width: 0;
        }

        input[type="checkbox"]:before {
          @apply w-4 h-4;
        }

        label {
          @apply flex flex-nowrap text-[12px];
        }
      }

      &__submit {
        @apply relative text-left;

        input {
          @apply text-paragraph-m transition-all cta inline-block whitespace-nowrap border border-solid border-black no-underline rounded-br-full rounded-full;
        }

        .wpcf7-spinner {
          @apply center-absolute-y right-5 lg:left-5 lg:right-auto;
        }
      }
    }
  }
}
